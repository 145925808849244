import { userApi, userApiUnprotected } from './api';

/**
 * Perform a login and generate a OTP
 */
const getOTP = async (email, password) => {
  const response = await userApiUnprotected.post('account/login', {
    email: email,
    password: password,
  });

  return response
};

/**
 * Perform the actual login
 *
 * @return User data
 */
const login = async (email, password, otp) => {
  const response = await userApiUnprotected.post('account/token', {
    email: email,
    password: password,
    otp: otp,
  });

  return response
};


const getCurrentUser = async () => {
  const response = await userApi.get('account/me');

  return response
};

const logout = async () => {
  const response = await userApi.post('account/logout');
  return response;
};

const forgotPassword = async (email) => {
  const response = await userApiUnprotected.post('account/reset-password/email', {
    email: email,
  });

  return response.data;
};

const checkResetToken = async (token, email) => {
  const response = await userApiUnprotected.post('account/reset-password/check-token', {
    token: token,
    email: email,
  });

  return response.data;
};

const resetPassword = async (password, token, email) => {
  try {
    const response = await userApiUnprotected.post('account/reset-password/reset', {
      password: password,
      token: token,
      email: email,
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

export { 
  getOTP, 
  login, 
  getCurrentUser, 
  logout, 
  forgotPassword, 
  checkResetToken, 
  resetPassword 
};
