<template>
  <div>
    <PasswordResetEmailForm
      v-if="!isPasswordFormShown"
      :token="token"
      @redirect="redirectToForgotPassword"
      @success="showPasswordForm"
    />
    <PasswordResetPasswordForm v-else :token="token" :email="email" />
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import PasswordResetEmailForm from '@/components/app/PasswordResetEmailForm.vue';
import PasswordResetPasswordForm from '@/components/app/PasswordResetPasswordForm.vue';
import { useRouter } from 'vue-router';

const email = ref('');
const isPasswordFormShown = ref(false);
const router = useRouter();

const token = computed(() => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
});

onMounted(async () => {
  if (!token.value) {
    redirectToForgotPassword();
  }
});

const showPasswordForm = (userEmail) => {
  email.value = userEmail;
  isPasswordFormShown.value = true;
};

const redirectToForgotPassword = () => {
  router.push({ name: 'forgot-password' });
};
</script>
