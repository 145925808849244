<template>
  <div>
    <h1>{{ $t('resetPassword.title') }}</h1>
    <p class="my-2">{{ $t('resetPassword.info') }}</p>

    <form>
      <forge-input
        v-model="v$.email.$model"
        :value="email"
        class="mt-4"
        class-input="w-full"
        label="E-Mail"
        is-block
        required
        @blur="v$.email.$touch"
      >
        <validation-text v-if="v$.email.$error" class="mt-1">
          {{ $t('login.emailRequired') }}
        </validation-text>
      </forge-input>

      <div class="flex items-center justify-between mt-6">
        <button
          class="btn btn--primary"
          :disabled="v$.$invalid"
          @click.prevent="validateToken"
        >
          {{ $t('resetPassword.confirm') }}
        </button>
      </div>
    </form>

    <div class="mt-6">
      <router-link :to="{ name: 'login' }">
        {{ $t('resetPassword.back') }}
      </router-link>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import ForgeInput from '@/components/app/Input.vue';
import ValidationText from '@/components/app/ValidationText.vue';
import { toaster } from '@/globals/toaster.js';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { checkResetToken } from '@/services/auth';

const email = ref('');
const rules = {
  email: {
    required,
  },
};

const emit = defineEmits(['success', 'redirect']);
const v$ = useVuelidate(rules, { email });
const { t } = useI18n();

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
});

const validateToken = async () => {
  try {
    const response = await checkResetToken(props.token, email.value);
    if (!response.valid) {
      redirectToForgotPassword();
      setTimeout(() => {
        toaster('error', t('resetPassword.error'));
      }, 200);
    } else {
      emit('success', email.value);
    }
  } catch (e) {
    redirectToForgotPassword();

    setTimeout(() => {
      toaster('error', e.response?.data?.message || t('resetPassword.error'));
    }, 200);
  }
};

const redirectToForgotPassword = () => {
  emit('redirect');
};
</script>
