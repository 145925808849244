<template>
  <div>
    <h1>{{ $t('forgotPassword.title') }}</h1>
    <div>
      <p class="mt-6 text-base">{{ $t('forgotPassword.intro') }}</p>
      <ul class="list-disc list-inside mb-6">
        <li class="pl-2">{{ $t('forgotPassword.step1') }}</li>
        <li class="pl-2">
          {{ $t('forgotPassword.step2') }}
        </li>
        <li class="pl-2">{{ $t('forgotPassword.step3') }}</li>
      </ul>
    </div>
    <form>
      <forge-input
        v-model="v$.email.$model"
        :value="email"
        class="mt-4"
        class-input="w-full"
        label="E-Mail"
        is-block
        required
        @blur="v$.email.$touch"
      >
        <validation-text v-if="v$.email.$error" class="mt-1">
          {{ $t('login.emailRequired') }}
        </validation-text>
      </forge-input>

      <div class="flex items-center justify-between mt-6">
        <button
          class="btn btn--primary"
          :disabled="v$.$invalid"
          @click.prevent="saveForgotPassword"
        >
          {{ $t('forgotPassword.send') }}
        </button>
      </div>
    </form>
    <br />
    <router-link class="mt-4" :to="{ name: 'login' }">
      {{ $t('forgotPassword.back') }}
    </router-link>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ForgeInput from '@/components/app/Input.vue';
import ValidationText from '@/components/app/ValidationText.vue';
import { toaster } from '@/globals/toaster.js';
import { useI18n } from 'vue-i18n';
import { forgotPassword } from '@/services/auth';

const { t } = useI18n();
const email = ref('');

const rules = { email: { required } };
const v$ = useVuelidate(rules, { email });

const saveForgotPassword = async () => {
  try {
    await forgotPassword(email.value);
    toaster('success', t('forgotPassword.success'));
  } catch (e) {
    toaster('error', t('forgotPassword.error'));
  }
};
</script>
