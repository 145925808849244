<template>
  <EmptyLayout>
    <div class="">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full md:w-2/3 lg:w-1/2 ml-auto mr-auto">
            <div :class="frontend_tag" class="w-1/2 mt-16 logo">
              <img :src="logoPath" class="px-8 py-4" />
            </div>
            <ForgotPassword class="p-20 rounded login"></ForgotPassword>
          </div>
        </div>
      </div>
    </div>
  </EmptyLayout>
</template>

<script setup>
import { computed } from 'vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import ForgotPassword from '@/components/app/ForgotPassword.vue';
import LogoASA from '@/assets/images/ASA.svg?url';
import LogoVKS from '@/assets/images/VKS.svg?url';

const logoPath = computed(() => {
  if (import.meta.env.VITE_FRONTEND_TAG === 'VKS') {
    return LogoVKS;
  } else {
    return LogoASA;
  }
});

const frontend_tag = computed(() => `${import.meta.env.VITE_FRONTEND_TAG}`);
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #eee;
}
.logo {
  background-color: #bb202b;
  img {
    height: 100px;
  }
  &::after {
    display: block;
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 25px 25px 0 0;
    border-color: #bc202b transparent transparent transparent;
  }
}
.logo.ASA {
  background-color: #bb202b;
  &::after {
    border-color: #bc202b transparent transparent transparent;
  }
}
.logo.VKS {
  background-color: #00122d;
  &::after {
    border-color: #00122d transparent transparent transparent;
  }
}
.login {
  background-color: #fff;
}
</style>
