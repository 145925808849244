import Login from '@/views/app/Login.vue';
import ForgotPassword from '@/views/app/ForgotPassword.vue';
import PasswordReset from '@/views/app/PasswordReset.vue';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
  },
];
