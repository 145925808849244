<template>
  <div>
    <h1>{{ $t('resetPassword.title') }}</h1>
    <div class="mt-6">
      <ValidationText v-if="passwordError" class="error-text">
        {{ passwordError }}
      </ValidationText>

      <ValidationText v-else-if="!isPasswordSet" class="error-text">
        {{ $t('resetPassword.passwordRequired') }}
      </ValidationText>

      <ValidationText v-else-if="!isPasswordValid" class="error-text">
        {{ $t('resetPassword.passwordInvalid') }}
      </ValidationText>

      <ValidationText v-else-if="!isSamePassword" class="error-text">
        {{ $t('resetPassword.passwordsNotMatching') }}
      </ValidationText>
    </div>
    <form class="mt-6">
      <ForgeInput
        v-model.trim.lazy="v$.password.$model"
        :value="password"
        class="mt-4"
        class-input="w-full"
        :label="$t('resetPassword.passwordLabel')"
        :placeholder="$t('resetPassword.password')"
        type="password"
        is-block
        required
        @blur="onPasswordBlur"
      />
      <ForgeInput
        v-model.trim.lazy="v$.passwordConfirm.$model"
        :value="passwordConfirm"
        class="mt-4"
        class-input="w-full"
        :label="$t('resetPassword.passwordConfirm')"
        :placeholder="$t('resetPassword.passwordConfirm')"
        type="password"
        is-block
        required
        @blur="v$.passwordConfirm.$touch"
      />

      <div class="flex items-center justify-between mt-6">
        <button
          class="btn btn--primary"
          :disabled="v$.$invalid && isPasswordValid"
          @click.prevent="doPasswordReset"
        >
          {{ $t('resetPassword.changePassword') }}
        </button>
      </div>
    </form>
    <div class="mt-6">
      <router-link class="text-blue-primary" :to="{ name: 'login' }">
        {{ $t('resetPassword.back') }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import ForgeInput from '@/components/app/Input.vue';
import ValidationText from '@/components/app/ValidationText.vue';
import { required, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { toaster } from '@/globals/toaster.js';
import { resetPassword } from '@/services/auth.js';

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
  email: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const router = useRouter();
const emit = defineEmits(['redirect']);
const password = ref('');
const passwordConfirm = ref('');
const passwordError = ref('');

const rules = {
  password: { required },
  passwordConfirm: {
    required,
    sameAs: sameAs(password),
  },
};

const v$ = useVuelidate(rules, { password, passwordConfirm });

const isPasswordValid = computed(() => {
  if (password.value.length > 0) {
    const regex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return regex.test(password.value);
  }
  return true;
});

const isPasswordSet = computed(() => {
  if (v$.value.password.$error) {
    return false;
  }
  if (!v$.value.password.required && v$.value.password.$dirty) {
    return false;
  }
  return true;
});

const isSamePassword = computed(() => {
  if (v$.value.passwordConfirm.$error) {
    return false;
  }
  if (!v$.value.passwordConfirm.sameAs && v$.value.passwordConfirm.$dirty) {
    return false;
  }
  return true;
});

const doPasswordReset = async () => {
  try {
    await resetPassword(password.value, props.token, props.email);
    router.push({ name: 'login' });
    setTimeout(() => {
      toaster('success', t('resetPassword.resetPasswordSuccess'));
    }, 200);
  } catch (e) {
    handleError(e);
  }
};

const handleError = (error) => {
  if (error.detail === 'token is invalid') {
    toaster('error', e.response?.data?.message || t('resetPassword.error'));
    redirectToForgotPassword();
  } else {
    passwordError.value = error.detail.replace(/\[|\]|'/gi, '');
    passwordError.value = passwordError.value.replace(/,/gi, '');
  }
};

const redirectToForgotPassword = () => {
  emit('redirect');
};

const onPasswordBlur = () => {
  password.value.$touch;
  passwordError.value = '';
};
</script>
